<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Products')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Add to order') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="isDisabledAddToOrder || !hasBaseAccess"
            @click="handleAddToOrder"
        />

        <q-btn
            v-if="this.appOptions.versionType !== 'simple'"
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Add to Purchase order') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="items.length <= 0"
            @click="handleAddToWarehouse"
        />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Reports')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="!hasBaseAccess"
            @click="showReports"
        />

        <q-btn
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Export excel') + `${items.length > 0 ? ` (${items.length})` : $t(' (filtered)')}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="items.length <= 0 && !serverParams || items.length >= 25"
            @click="downloadExcel"
        />

        <q-btn
            color="positive"
            text-color="white"
            :label="$t('Import')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="!hasBaseAccess"
            @click="openAddOffers"
        />

        <q-btn-dropdown
            color="light-blue-9"
            size="sm"
            no-caps
            unelevated
            :label="$t('Add product')"
            :disable="!hasBaseAccess"
        >
          <q-list>
            <q-item clickable v-close-popup @click="handleCreate('simple')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Simple') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="this.appOptions.versionType !== 'simple'" clickable v-close-popup @click="handleCreate('grouped')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Grouped') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="this.appOptions.versionType !== 'simple'" clickable v-close-popup @click="handleCreate('bundle')">
              <q-item-section>
                <q-item-label>
                  {{ $t('Bundle') }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              'states==i': states,
              types: types
            }
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
            @update="handleFilterUpdate"
        />
      </q-card-section>

      <q-card-section
          v-if="isNeededWarehouse"
          class="row bg-teal border-bottom q-pa-sm items-center justify-center text-center text-subtitle1"
          style="min-height: 80vh;"
      >
        {{ $t('You need to create warehouse. You can make this from') }}

        <router-link
            to="/warehouse"
            class="text-success q-ml-sm"
        >
          {{ $t('here.') }}
        </router-link>
      </q-card-section>

      <q-card-section
          v-else
          class="row q-pa-none"
      >
        <status-filter
            class="hide-on-mobile"
            :outside-selected-status="selectedStatusFilter"
            :statuses="status"
            @on-change="onStatusFilter"
        />
        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="offers"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="offersLoading"
              :filter="filter"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              selection="multiple"
              v-model:selected="items"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:header-selection="props">
              <q-checkbox
                  size="xl"
                  :model-value="items.length > 0"
                  color="amber-6"
                  @click="handleSelectAll(props)"
              />
            </template>

            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <offer-row
                  :data="props"
                  :items="items"
                  @dblclick="onRowDblClick"
                  @check="handleCheck"
                  @item="handleItemOpen"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <order-products-modal ref="orderModal"/>

    <acceptance-items-modal ref="acceptanceModal"/>

    <offer-import-modal ref="offersImport"/>

    <offers-item-move ref="offersItemMove"/>

    <warehouse-reports ref="warehouseReports"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import WarehouseReports from '../../components/reports/WarehouseReports'
import Search from '../../components/search/Search'
import AcceptanceItemsModal from '../../components/modals/AcceptanceItemsModal.vue'
import OrderProductsModal from '../../components/modals/OrderProductsModal.vue'
import OfferImportModal from '../../components/imports/OfferImportModal.vue'
import OfferRow from '../../components/products/OfferRow.vue'
// import ItemsRow from '../../components/items-table/ItemsRow.vue'
// Utils
import { buildQuery } from '../../utils/query-utils'
import OffersItemMove from '@/apps/app/components/items/OffersItemMove.vue'
import StatusFilter from "@/apps/app/components/filters/StatusFilter.vue";

export default {
  name: 'Products',
  components: {
    StatusFilter,
    OffersItemMove,
    FiltersCollapse,
    WarehouseReports,
    Search,
    AcceptanceItemsModal,
    OrderProductsModal,
    OfferImportModal,
    OfferRow
    // ItemsRow
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      fallbackImage: 'assets/img/fallback-image/package.png',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'items',
          align: 'center'
        }
      ],
      isNeededWarehouse: false,
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey',
        defected: 'grey'
      },
      isOpenFilters: false,
      statuses: [],
      activatedFields: [
        'id',
        'name',
        'article',
        'sku',
        'type',
        'price.to',
        'price.from',
        'extId',
        'items.to',
        'items.from',
        'state==i',
        'shop',
        'created.to',
        'created.from',
        'warehouse==i',
        'barcodes::text'
      ],
      states: [
        { id: 'normal', title: this.$t('On hand') },
        { id: 'expected', title: this.$t('Expected') },
        { id: 'new', title: this.$t('Received') },
        { id: 'blocked', title: this.$t('Blocked') },
        { id: 'booked', title: this.$t('Booked') },
        { id: 'defected', title: this.$t('Defected') }
      ],
      status:  [
        {
          id: 1,
          group: 'Default',
          color: 'grey-5',
          buttons: [
            {
              id: 'normal',
              title: 'Active',
              editableField: false,
              transitions: [],
              color: 'positive'
            },
            {
              id: 'inactive',
              title: 'Inactive',
              color: 'grey',
              editableField: false,
              transitions: []
            },
            {
              id: 'blocked',
              title: 'Blocked',
              color: 'green-7',
              editableField: false,
              transitions: []
            },
            {
              id: 'archived',
              title: 'Archived',
              color: 'danger',
              editableField: false,
              transitions: []
            },
          ]
        },
      ],
      types: [
        { id: 'simple', title: this.$t('simple') },
        { id: 'grouped', title: this.$t('grouped') },
        { id: 'pallet', title: this.$t('pallet') }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([
      'offers',
      'offersItemsNumber',
      'offersLoading',
      'hasBaseAccess',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.offersItemsNumber
          : this.$t('Filter')
    },
    isDisabledAddToOrder () {
      // Order can be created only with offers from one shop
      return this.items.length <= 0 || this.items.find(x => x._embedded.shop.id !== this.items[0]._embedded.shop.id)
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state'
      })

      if (status && status.values) {
        return status.values
      }

      return status
        ? [status.value]
        : []
    },
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadOffers'
    ]),
    ...mapMutations([
      'setOffer',
      'setOffers',
      'addErrorNotification',
    ]),
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      query.allFilters = true
      this.loadOffers(query)
    },
    downloadExcel () {
      const query = buildQuery(this.pagination)

      if (this.items.length > 0) {
        query.filter = this.items.map((item) => {
          return { type: 'eq', field: 'id', value: item.id }
        })
      } else {
        query.filter = this.serverParams.filter
      }

      return this.$service.offer.downloadAll(query)
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFilterUpdate ({ value, oldValue }) {
      if (oldValue['state==i'].length <= 0 && value['state==i'].length > 0 && !value.items.from) {
        value.items.from = 1
      }
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleItemOpen (item) {
      this.$refs.offersItemMove.open(item)
    },
    handleCheck (item) {
      let hasItem = false

      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
    handleCreate (type) {
      this.$router.push(`/products/entity/${type}`)
    },
    handleAddToOrder () {
      this.$refs.orderModal.show(this.items.map(item => ({ ...item, count: 1 })))
    },
    handleAddToWarehouse () {
      const updatedItems = this.items.map(item => {
        return {
          ...item,
          count: 1,
          sku: Array.isArray(item.barcodes) && item.barcodes.length >= 1
              ? item.barcodes[0]
              : '',
          status: 'new',
          dimensions: item.dimensions && typeof item.dimensions === 'object'
              ? item.dimensions
              : { x: 0, y: 0, z: 0 }
        }
      })

      this.$refs.acceptanceModal.show(updatedItems)
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      this.updateParams(query)

      return this.loadOffers(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    handleOrderByPrice (descending) {
      const orderBy = (this.serverParams['order-by'] || []).filter(val => val.field !== 'price')
      return this.onRequest({ pagination: { ...this.pagination, 'order-by': orderBy, descending, sortBy: 'price' } })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    showReports () {
      this.$refs.warehouseReports.open()
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    openAddOffers () {
      this.$refs.offersImport.open()
    },
    onRowDblClick (offer) {
      if (!offer._embedded?.shop?.id) {
        this.addErrorNotification(`Product ${offer.id} does not have shop!`)
        return
      }

      this.$router.push(`/products/entity/${offer.type}/${offer._embedded?.shop?.id}/${offer.id}`)
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    }
  }
}
</script>
