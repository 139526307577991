<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <td class="q-td text-left">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </td>

    <q-td
        key="image"
        :props="data"
        auto-width
    >
      <img
          :src="data.row.image ? data.row.image : fallbackImage"
          style="width: 80px; height: 70px; object-fit: contain;"
          @error="onImageLoadFailure"
      >
    </q-td>

    <q-td
        key="id"
        :props="data"
        style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
    >
      <div class="text-subtitle1">
        <q-badge :class="`${data.row.type === 'bundle' ? 'bg-purple-4' : ''} q-mr-sm`">{{ data.row.type }}</q-badge>
        <a :href="handleClickName(data.row)" class="text-black"
           style="text-decoration: none;">{{ `${data.row.name} (${data.row.id})` }}</a>
      </div>

      <div class="text-caption">
        <strong v-if="data.row.sku">
            {{ data.row.sku }}
        </strong>
          <br v-if="data.row.sku"/>
          <div v-if="data.row.barcodes && data.row.barcodes.length > 0">
              {{ data.row.barcodes.join(', ') }}
          </div>
      </div>
    </q-td>

    <q-td
        key="state"
        :props="data"
        auto-width
    >
      <super-dropdown :model="schema"/>
    </q-td>

    <q-td
        key="shop"
        :props="data"
        auto-width
    >
      <div v-if="data.row._embedded && data.row._embedded.shop">
        {{ data.row._embedded.shop.name }}
      </div>
    </q-td>

    <q-td
        key="price"
        :props="data"
        auto-width
    >
      <strong v-if="!data.row.price">
        -
      </strong>

      <strong v-else>
        {{ data.row.price }}
      </strong>
    </q-td>

    <q-td
        key="items"
        :props="data"
        auto-width
    >
      <!-- <q-btn
        size="md"
        color="light-blue-9"
        round
        dense
        :icon="data.expand ? 'expand_less' : 'expand_more'"
        @click="data.expand = !data.expand"
      /> -->
      <span v-if="!data.row.items || data.row.items.length === 0 || !data.row.items[0]">
        <strong>-</strong><br>
      </span>

      <span v-else>
        <table class="table table-valign-middle">
          <thead>
            <tr>
              <th>{{ $t('Status') }}</th>

              <th>{{ $t('Amount') }}</th>

              <th>{{ $t('Warehouse') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
                v-for="item in isItems"
                :key="item.id"
                @click="handleItemsClick(item)"
            >
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[item.state]}`">
                  {{ $t(stateName[item.state] || item.state) }}
                </label>
              </td>

              <td>
                <strong>{{ item.count }}</strong>
              </td>

              <td>{{ item.warehouse }}</td>
            </tr>
          </tbody>
        </table>
      </span>
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'

import { doCopy } from '../../utils/copy-utils'

export default {
  name: 'OfferRow',
  emits: ['check', 'dblclick', 'item'],
  components: {
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Object,
      required: true,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked for Sales',
        booked: 'Booked',
        new: 'Received',
        shipped: 'Shipped'
      },
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      offerStates: [
        {
          buttons: [
            { id: 'normal', title: 'Active', transitions: ['inactive', 'blocked'], color: 'positive' },
            {
              id: 'inactive',
              title: 'Inactive',
              transitions: this.data.row.items === 0 || !this.data.row.items ? ['normal', 'blocked', 'archived'] : ['normal', 'blocked'],
              color: 'grey'
            },
            { id: 'blocked', title: 'Blocked', transitions: ['normal', 'inactive'], color: 'green-7' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'archived', title: 'Archived', transitions: [], color: 'danger' }
          ]
        }
      ]
    }
  },
  computed: {
    isItems() {
      let result = []
      if (this.data.row.items) {
        result = this.data.row.items
        return result.filter(e => e.state !== 'deleted').sort((a, b) => {
          let fa = a.state.toLowerCase(),
              fb = b.state.toLowerCase()
          if (fa[0] < fb[0]) {
            return -1
          }
          if (fa[0] > fb[0]) {
            return 1
          }
          return 0
        })
      }
      return result
    },
    schema() {
      const value = this.offerStates[0].buttons.find(({ id }) => id === this.data.row.state) || {
        id: 'normal',
        title: 'Active'
      }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          const query = {
            filter: [
              { type: 'eq', field: 'shop', value: this.data.row._embedded.shop.id }
            ]
          }

          this.$service.offer.save({ state }, this.data.row.id, this.data.row._embedded.shop.id, {})
              .then(item => {
                this.upsertOffer(item)
              })
        },
        value,
        options: this.offerStates
      }

      return option
    }
  },
  methods: {
    ...mapMutations([
      'upsertOffer'
    ]),
    handleClickName(row) {
      if (!row?._embedded?.shop?.id) {
        this.addErrorNotification(`Product ${this.data.row.id} does not have shop!`)
        return
      }

      return `/#/products/entity/${row.type}/${row._embedded?.shop?.id}/${row.id}`
    },
    onRowDblClick() {
      this.$emit('dblclick', this.data.row)
    },
    handleItemsClick(item) {
      this.$emit('item', {
        offer: this.data.row,
        item: item
      })
    },
    handleCheck() {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure(e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    copy(value) {
      doCopy(value)
    }
  }
}
</script>
